import {ApplicationRef, ChangeDetectorRef, Directive, OnDestroy, OnInit} from '@angular/core';
import {IReactionDisposer, autorun, runInAction} from 'mobx';

const processedAppRefs = new Map<ApplicationRef, number>();

/**
 * This host directive is an analog to `*mobxAutorun` directive from `mobx-angular` package.
 * When added to `hostDirectives` of component - it'll automatically rerender it (via `detectChanges` call),
 * when any mobx `observable` or `computed` (that is used inside it) is changed.
 */
@Directive({
  selector: '[wReactive]',
  standalone: true,
})
export class ReactiveDirective implements OnInit, OnDestroy {
  protected dispose: IReactionDisposer | null = null;
  private readonly originalTick: ApplicationRef['tick'];

  constructor(
    protected appRef: ApplicationRef,
    protected cd: ChangeDetectorRef,
  ) {
    this.originalTick = this.appRef.tick;
  }

  ngOnInit() {
    // Optimizes update of observables (and related reactions), which are also marked as angular component inputs
    const usedRefsCount = processedAppRefs.get(this.appRef);

    if (usedRefsCount === undefined) {
      processedAppRefs.set(this.appRef, 1);

      const {originalTick} = this;

      this.appRef.tick = function (...args: Parameters<ApplicationRef['tick']>) {
        return runInAction(() => originalTick.apply(this, args));
      };
    } else {
      processedAppRefs.set(this.appRef, usedRefsCount + 1);
    }

    this.dispose = autorun(() => {
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this.dispose?.();

    const usedRefsCount = processedAppRefs.get(this.appRef);

    if (usedRefsCount && usedRefsCount > 1) {
      processedAppRefs.set(this.appRef, usedRefsCount - 1);
    } else {
      processedAppRefs.delete(this.appRef);

      this.appRef.tick = this.originalTick;
    }
  }
}
