import {Injectable} from '@angular/core';
import {computed, flow, flowResult, makeObservable, observable} from 'mobx';

import {Request} from '@shared/services/http-resource';
import {LcapWorkflowStage} from '@shared/modules/lcap/lcap.types';

import {getIdFromSlug} from '../utils/get-id-from-slug';

import {HttpResource} from './http-resource';
import {RouterService} from './router.service';
import {User} from './auth.types';
import {AppPage} from './app-pages.service';

export interface PortalApp {
  name: string;
  slug: string;
  icon_url?: string;
  workato_db_table_id?: number;
  creation_page?: {
    id: string;
    slug: string;
  };
}

export interface AppTab {
  id: string;
  name: string;
  page: Pick<AppPage, 'id' | 'slug'> | null;
  kind: 'user_defined' | 'new_request';
  slug: string;
}

@Injectable({providedIn: 'root'})
export class PortalAppsService {
  @observable.shallow apps: PortalApp[] = [];
  @observable loaded = false;

  protected loadPromise: Promise<void> | null = null;

  private resource = new HttpResource({url: '/portal/api/apps/{{appId}}/{{entity}}.json'});

  private tabsResource = new HttpResource({
    url: '/portal/api/apps/{{appSlug}}/tabs.json',
  });

  constructor(private routerService: RouterService<{appSlug?: string}>) {
    makeObservable(this);
  }

  @computed
  get currentApp(): PortalApp | null {
    const appSlug = this.routerService.params.appSlug;

    return appSlug ? this.findAppBySlug(appSlug) : null;
  }

  load(): Promise<void> {
    this.loadPromise ??= flowResult(this.loadApps()).catch(err => {
      this.loadPromise = null;
      throw err;
    });

    return this.loadPromise;
  }

  async getAppUsers(): Promise<Array<Pick<User, 'id' | 'name'>>> {
    return this.resource.get({appId: this.currentApp!.slug, entity: 'users'});
  }

  async getAppWorkflowStages(): Promise<LcapWorkflowStage[]> {
    return this.resource.get({appId: this.currentApp!.slug, entity: 'workflow_stages'});
  }

  findAppBySlug(appSlug: PortalApp['slug']): PortalApp | null {
    const appId = getIdFromSlug(appSlug);

    return this.apps.find(app => getIdFromSlug(app.slug) === appId) ?? null;
  }

  loadTabs(appSlug: PortalApp['slug']): Request<AppTab[]> {
    return this.tabsResource.get({appSlug});
  }

  @flow
  protected *loadApps(): Generator<any, void, any> {
    this.apps = yield this.resource.getAll();
    this.loaded = true;
  }
}
