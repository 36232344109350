import {Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable, Subject} from 'rxjs';
import {catchError, finalize, mergeMap, switchMap, tap, throttleTime} from 'rxjs/operators';
import {noop} from 'lodash';

import {subscriptions} from '@shared/services/subscriptions';
import {Request, getRequestAsObservable} from '@shared/services/http-resource';
import {FormulaEvaluationError} from '@shared/modules/formula-interpreter/formula-interpreter.service';
import {AbstractLcapLoggerService} from '@shared/modules/lcap/abstract-lcap-logger.service';
import {DynamicConditionEvaluationError} from '@shared/modules/condition/dynamic-condition';

import {HttpResource} from './http-resource';
import {PortalApp, PortalAppsService} from './portal-apps.service';
import {AppPage, AppPagesService} from './app-pages.service';

export type PageError = FormulaEvaluationError | DynamicConditionEvaluationError;
export type PageErrorDebugInfo = NonNullable<PageError['debugInfo']>;
export type PageErrorKey = string;
export interface PageErrorData {
  appSlug: PortalApp['slug'];
  pageId: AppPage['id'];
  message: string;
  debugInfo: PageErrorDebugInfo;
}

export const ERROR_THROTTLE_INTERVAL = 60_000;

@Injectable()
export class LcapLoggerService extends AbstractLcapLoggerService {
  private resource = new HttpResource({
    url: '/portal/api/apps/{{appSlug}}/logs.json',
  });

  private errors = new Subject<PageError>();
  private errorStreams = new Map<PageErrorKey, BehaviorSubject<PageErrorData>>();
  private errorsReporter$: Observable<void>;
  private subs = subscriptions();

  constructor(
    private appsService: PortalAppsService,
    private pagesService: AppPagesService,
  ) {
    super();

    this.errorsReporter$ = this.getErrorsReporterObservable();
    this.subs.add(this.errorsReporter$.subscribe());
  }

  override handleUserFormulaEvaluationError(error: PageError): LcapHandledBuilderError {
    this.errors.next(error);

    return new LcapHandledBuilderError('Error in user formula/condition');
  }

  sendPageError({appSlug, pageId, message, debugInfo}: PageErrorData, eventsCount = 1): Request<void> {
    return this.resource.post(
      {appSlug},
      {
        page_id: pageId,
        data: {
          message,
          debug_info: debugInfo,
          events_count: eventsCount,
        },
      },
    );
  }

  private getErrorsReporterObservable(): Observable<void> {
    return this.errors.pipe(
      mergeMap(err => {
        const appSlug = this.appsService.currentApp?.slug;
        const pageId = this.pagesService.currentPage?.id;
        const debugInfo = err.debugInfo;

        // TODO: send errors for Public Access.
        // For now we don't have page ID in Public Access
        if (!appSlug || !pageId || !debugInfo) {
          return EMPTY;
        }

        const key = this.getErrorKey(pageId, debugInfo);
        const errorData: PageErrorData = {
          message: `An error has occurred on the page: "${err.message}"`,
          appSlug,
          pageId,
          debugInfo,
        };
        const errorStream = this.errorStreams.get(key);

        // Emit to existing stream if it's present
        if (errorStream) {
          errorStream.next(errorData);

          return EMPTY;
        }

        // Otherwise, make throttled stream
        const newErrorStream = new BehaviorSubject<PageErrorData>(errorData);

        this.errorStreams.set(key, newErrorStream);

        return this.getThrottledErrorObservable(newErrorStream);
      }),
    );
  }

  private getThrottledErrorObservable(errorStream: BehaviorSubject<PageErrorData>): Observable<void> {
    let count = 0;

    return errorStream.pipe(
      tap(() => count++),
      throttleTime(ERROR_THROTTLE_INTERVAL, undefined, {leading: true, trailing: true}),
      switchMap(errorData => {
        const isLeading = count === 1;

        // For the leading reset the count immediately
        if (isLeading) {
          count = 0;
        }

        return this.getSendErrorObservable(errorData, isLeading ? 1 : count).pipe(
          finalize(isLeading ? noop : () => (count = 0)),
        );
      }),
    );
  }

  private getSendErrorObservable(data: PageErrorData, eventsCount?: number): Observable<void> {
    return getRequestAsObservable(this.sendPageError(data, eventsCount)).pipe(catchError(() => EMPTY));
  }

  private getErrorKey = (pageId: AppPage['id'], debugInfo: PageErrorDebugInfo): PageErrorKey =>
    JSON.stringify([pageId, ...debugInfo]);
}

export class LcapHandledBuilderError extends Error {
  override name = 'LcapHandledBuilderError';
}
