import {cloneDeep} from 'lodash';

import {LCAP_DEFAULT_PAGE_BACKGROUND} from './lcap-page.constants';
import {
  InsightsDashboardPageContent,
  InsightsDashboardPageContentResponse,
  LcapDefaultPageContent,
  LcapDefaultPageContentResponse,
  LcapPageMaxWidth,
  LcapPageType,
} from './lcap-page.types';

const LCAP_DEFAULT_PAGE_CONTENT_DEFAULTS: Omit<LcapDefaultPageContent, 'maxWidth'> = {
  background: LCAP_DEFAULT_PAGE_BACKGROUND,
  layout: [],
  type: 'common',
  variables: [],
  handlers: {'page-load': null},
};

const LCAP_INSIGHTS_PAGE_CONTENT_DEFAULTS: InsightsDashboardPageContent = {
  layout: [],
  type: 'dashboard',
  maxWidth: getDefaultPageMaxWidth('dashboard'),
};

export function normalizeDefaultPageContent(content: LcapDefaultPageContentResponse): LcapDefaultPageContent {
  const type = content.type ?? LCAP_DEFAULT_PAGE_CONTENT_DEFAULTS.type;

  return {
    ...cloneDeep(LCAP_DEFAULT_PAGE_CONTENT_DEFAULTS),
    ...content,
    type,
    maxWidth: content.maxWidth ?? getDefaultPageMaxWidth(type),
  };
}

export function normalizeInsightsPageContent(
  content: InsightsDashboardPageContentResponse,
): InsightsDashboardPageContent {
  return {
    ...cloneDeep(LCAP_INSIGHTS_PAGE_CONTENT_DEFAULTS),
    ...content,
  };
}

export function getDefaultPageMaxWidth(pageType: LcapPageType): LcapPageMaxWidth {
  return pageType === 'dashboard' ? 'full' : 'fixed';
}
