import {Injectable} from '@angular/core';
import {action, flow, makeObservable, observable} from 'mobx';

import {LcapPageContent, LcapPageContentResponse} from '@shared/modules/lcap-page/lcap-page.types';
import {normalizeDefaultPageContent} from '@shared/modules/lcap-page/lcap-page.helpers';
import {LcapPageDataSources} from '@shared/modules/lcap/lcap.types';

import {getIdFromSlug} from '../utils/get-id-from-slug';

import {HttpResource} from './http-resource';
import {PortalApp} from './portal-apps.service';
import {Task} from './tasks.service';
import {AppPublicPage} from './public-access.service';

export interface AppPageResponse {
  id: string;
  content: LcapPageContentResponse;
  schema: LcapPageDataSources;
}

export type AppPage = AppPageResponse & {
  slug: string;
  content: LcapPageContent;
};

export interface AppPageMeta {
  appSlug: PortalApp['slug'];
  pageSlug: AppPage['slug'];
  taskId?: Task['id'];
  workflowRequestCanBeUpdated?: boolean;
  publicAccessToken?: AppPublicPage['id'];
}

type AppPageCacheKey = `${string}/${string}`;

@Injectable({providedIn: 'root'})
export class AppPagesService {
  @observable.ref currentPage: AppPage | null = null;

  private resource = new HttpResource({
    url: '/portal/api/apps/{{appSlug}}/pages/{{pageSlug}}.json',
  });

  private pages = new Map<AppPageCacheKey, AppPage>();

  constructor() {
    makeObservable(this);
  }

  @action
  setCurrentPage(page: AppPage | null) {
    this.currentPage = page;
  }

  @action
  resetCurrentPage() {
    this.setCurrentPage(null);
  }

  @flow
  *getPage(appSlug: PortalApp['slug'], pageSlug: AppPage['slug']): Generator<any, AppPage, any> {
    const cacheKey: AppPageCacheKey = `${getIdFromSlug(appSlug)}/${getIdFromSlug(pageSlug)}`;
    const loadedPage = this.pages.get(cacheKey);
    const page: AppPage = loadedPage ?? this.normalizePage(yield this.resource.get({appSlug, pageSlug}), pageSlug);

    if (!loadedPage) {
      this.pages.set(cacheKey, page);
    }

    this.setCurrentPage(page);

    return page;
  }

  private normalizePage(page: AppPageResponse, slug: AppPage['slug']): AppPage {
    return {
      ...page,
      slug,
      content: normalizeDefaultPageContent(page.content),
    };
  }
}
