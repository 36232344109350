import {v4 as uuid} from 'uuid';
import {remove} from 'lodash';
import {Injectable} from '@angular/core';

import {Toast, ToastOptions} from '../components/toast/toasts.types';
import {Timer} from '../utils/timer';

const DEFAULT_DELAY = 5000;
const DEFAULT_DELAY_WITH_ACTION = 10000;

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  toasts: Toast[] = [];

  info(title: string, content?: string, options?: ToastOptions): Toast['id'] {
    return this.showMessage('info', title, content, options);
  }

  success(title: string, content?: string, options?: ToastOptions): Toast['id'] {
    return this.showMessage('success', title, content, options);
  }

  error(title: string, content?: string, options?: ToastOptions): Toast['id'] {
    return this.showMessage('error', title, content, options);
  }

  showMessage(type: Toast['type'], title: string, content?: Toast['content'], options?: ToastOptions): Toast['id'] {
    if (!title && !content) {
      return '';
    }

    options = this.getOptions(options);

    const id = uuid();
    const toast: Toast = {type, title, id, content, options};

    this.toasts.push(toast);

    if (options.removeDelay) {
      toast.timer = this.createRemovingTimer(toast.id, options.removeDelay);
    }

    return id;
  }

  clear() {
    // Preserving the reference to `toasts` array
    this.toasts.length = 0;
  }

  remove(id: Toast['id']) {
    const toast = this.find(id);

    if (!toast) {
      return;
    }

    if (toast.timer) {
      toast.timer.stop();
    }

    remove(this.toasts, toast);
  }

  update(id: Toast['id'], newToast: Partial<Toast>): boolean {
    const toast = this.find(id);

    if (!toast) {
      return false;
    }

    const hasActiveTimer = toast.timer?.isRunning;

    if (toast.timer) {
      toast.timer.stop();
    }

    Object.assign(toast, newToast);

    toast.options = this.getOptions(toast.options);

    if (toast.options.removeDelay) {
      toast.timer = this.createRemovingTimer(toast.id, toast.options.removeDelay);

      if (hasActiveTimer) {
        toast.timer.resume();
      }
    }

    return true;
  }

  private find(id: Toast['id']): Toast | undefined {
    return this.toasts.find(item => item.id === id);
  }

  private getOptions(options: ToastOptions | undefined): ToastOptions {
    return {
      removeDelay: options?.action ? DEFAULT_DELAY_WITH_ACTION : DEFAULT_DELAY,
      actionRemoveToast: true,
      ...(options || {}),
    };
  }

  private createRemovingTimer(id: Toast['id'], delay: NonNullable<ToastOptions['removeDelay']>): Timer {
    const timer = new Timer(() => {
      this.remove(id);
    }, delay);

    // Resume will be called when the toast will be shown.
    timer.pause();

    return timer;
  }
}
