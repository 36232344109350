<ng-container [ngSwitch]="controlType">
  <button
    *ngSwitchCase="'button'"
    #button
    [ngClass]="classes"
    [type]="actionType"
    [disabled]="inactive"
    [attr.aria-label]="ariaLabel"
  >
    <ng-container *ngTemplateOutlet="bodyTml" />
  </button>

  <a
    *ngSwitchCase="'router-link'"
    #routerLink
    [ngClass]="classes"
    [attr.target]="target"
    [attr.aria-label]="ariaLabel"
    [routerLink]="!inactive ? parsedHref!.pathname : undefined"
    [queryParams]="!inactive ? parsedHref!.query : undefined"
    [fragment]="!inactive ? urlFragment : undefined"
  >
    <ng-container *ngTemplateOutlet="bodyTml" />
  </a>

  <a
    *ngSwitchCase="'native-link'"
    #nativeLink
    [ngClass]="classes"
    [attr.href]="!inactive ? href : undefined"
    [attr.download]="download"
    [attr.target]="nativeLinkTarget"
    [attr.aria-label]="ariaLabel"
  >
    <ng-container *ngTemplateOutlet="bodyTml" />
  </a>
</ng-container>

<ng-template #bodyTml>
  <span class="button__wrapper">
    <w-svg-icon *ngIf="iconId" iconClass="button__icon" [iconId]="iconId" />
    <span #content class="button__content">
      <ng-content />
    </span>
  </span>

  <span *ngIf="pending" class="button__spinner">
    <w-spinner [size]="spinnerSize" />
  </span>
</ng-template>
