import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import URL from 'url-parse';
import {CommonModule, NgClass} from '@angular/common';

import {WSimpleChanges} from '../../types/angular';
import {SpinnerComponent} from '../spinner/spinner.component';
import {Hash, LinkTarget} from '../../types';
import {SvgIconComponent} from '../svg-icon/svg-icon.component';
import {RouterModule} from '../../modules/router/router.module';

import {
  ButtonActionType,
  ButtonControlType,
  ButtonLayoutView,
  ButtonSize,
  ButtonTheme,
  ButtonType,
  IconPosition,
} from './button.types';

@Component({
  selector: 'w-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule, SvgIconComponent, SpinnerComponent],
})
export class ButtonComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() theme: ButtonTheme = 'brand';
  @Input() size: ButtonSize = 'default';
  @Input() type: ButtonType = 'default';
  @Input() textView: ButtonLayoutView = 'default';
  @Input() iconView: ButtonLayoutView = 'default';
  @Input() ariaLabel: string;

  @Input() iconPosition: IconPosition = 'left';
  @Input() actionType: ButtonActionType = 'button';
  @Input() @HostBinding('class.full-width-button') fullWidth = false;
  @Input('spinnerSize') inputSpinnerSize?: SpinnerComponent['size'];
  @Input() iconId?: string | null;
  @Input() pending = false;
  @Input() highlighted = false;
  @Input() disabled = false;
  @Input() href?: string;
  @Input() nativeNavigation = false;
  @Input() download?: string;
  @Input() target: LinkTarget = '_self';

  @Output() onClick = new EventEmitter<MouseEvent>();
  @Output() onKeyDown = new EventEmitter<KeyboardEvent>();

  @ViewChildren('button,routerLink,nativeLink') actualElements?: QueryList<
    ElementRef<HTMLButtonElement | HTMLAnchorElement>
  >;

  @ViewChild('content') contentEl?: ElementRef<HTMLElement>;

  iconPositionClassName = '';
  parsedHref: URL<Hash<string | undefined>> | null = null;
  spinnerSize: SpinnerComponent['size'] = 'small';

  protected changeDetection = inject(ChangeDetectorRef);

  private contentObserver?: MutationObserver;

  ngOnChanges(changes: WSimpleChanges<ButtonComponent>) {
    if (changes.href) {
      this.parsedHref = this.href ? new URL(this.href, true) : null;
    }

    if (changes.iconPosition && !changes.iconPosition.firstChange) {
      this.iconPositionClassName = this.getIconPositionClassName();
    }

    if (changes.size || changes.inputSpinnerSize) {
      this.spinnerSize = this.inputSpinnerSize || this.defaultSpinnerSize;
    }
  }

  ngAfterViewInit() {
    if (this.iconId) {
      this.iconPositionClassName = this.getIconPositionClassName();
      this.changeDetection.detectChanges();
    }

    this.contentObserver = new MutationObserver(() => {
      this.iconPositionClassName = this.getIconPositionClassName();
      this.changeDetection.markForCheck();
    });
    this.contentObserver.observe(this.contentEl!.nativeElement, {childList: true, characterData: true, subtree: true});
  }

  ngOnDestroy() {
    this.contentObserver?.disconnect();
  }

  get classes(): NgClass['ngClass'] {
    return {
      [`button button_${this.theme} button_${this.size} ${this.iconPositionClassName}`]: true,
      'button_full-width': this.fullWidth,
      'button_outline': this.type === 'outline',
      'button_flat': this.type === 'flat',
      'button_pending': this.pending,
      'button_disabled': this.inactive,
      'button_highlighted': this.highlighted,
      [`button_text-view_${this.textView}`]: true,
      [`button_icon-view_${this.iconView}`]: true,
    };
  }

  get actualElement(): HTMLButtonElement | HTMLAnchorElement | undefined {
    return this.actualElements?.first?.nativeElement;
  }

  get nativeLinkTarget(): LinkTarget {
    /** fix unloading page context in FF bug when downloading file
     * @see https://bugzilla.mozilla.org/show_bug.cgi?id=564744 (old, but seems still actual)
     * @see https://bugzilla.mozilla.org/show_bug.cgi?id=1084399 - similar + proposal https://github.com/whatwg/html/issues/4489
     */
    return this.download !== undefined && this.target === '_self' && !this.isSameOriginHref ? '_blank' : this.target;
  }

  get isSameOriginHref(): boolean {
    return this.parsedHref?.origin === location.origin;
  }

  get controlType(): ButtonControlType {
    if (!this.href) {
      return 'button';
    } else if (
      this.nativeNavigation ||
      this.target !== '_self' ||
      this.download !== undefined ||
      !this.isSameOriginHref
    ) {
      return 'native-link';
    } else {
      return 'router-link';
    }
  }

  get inactive(): boolean {
    return this.disabled || this.pending;
  }

  get urlFragment(): string | undefined {
    return this.parsedHref?.hash ? this.parsedHref.hash.replace(/^#/, '') : undefined;
  }

  focus() {
    this.actualElement?.focus();
  }

  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent) {
    if (this.inactive) {
      event.stopImmediatePropagation();
      event.preventDefault();

      return;
    }

    this.onClick.emit(event);
  }

  @HostListener('keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    if (this.inactive) {
      event.stopImmediatePropagation();
      event.preventDefault();

      return;
    }

    this.onKeyDown.emit(event);
  }

  protected get defaultSpinnerSize(): SpinnerComponent['size'] {
    return this.size === 'giant' ? 'default' : 'small';
  }

  private getIconPositionClassName(): string {
    if (!this.contentEl || !this.iconId) {
      return '';
    }

    const {childNodes, innerText} = this.contentEl.nativeElement;
    const iconPosition: IconPosition = childNodes.length > 0 && Boolean(innerText) ? this.iconPosition : 'center';

    return `button_icon-${iconPosition}`;
  }
}
