import {isEqual, pick} from 'lodash';
import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, OnChanges, Output} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable} from 'rxjs';
import {distinctUntilChanged, skip, switchMap, tap, throttleTime} from 'rxjs/operators';

import {subscriptions} from '../services/subscriptions';
import {isElementVisible} from '../utils/dom/is-element-visible';
import {WSimpleChanges} from '../types/angular';

export interface ElementDimension {
  width: number;
  height: number;
}

export interface ResizeObservedElement {
  element: HTMLElement;
  onChange: Observable<ElementDimension>;
}

const DEFAULT_THROTTLE_TIME = 100;

@Directive({
  selector: '[wElementResize]',
  standalone: true,
})
export class ElementResizeDirective implements AfterViewInit, OnChanges, ResizeObservedElement {
  @Input('wElementResizeThrottleTime') throttleTime = DEFAULT_THROTTLE_TIME;
  @Input('wElementResizeSkipFirstEvent') skipFirstEvent = false;
  @Input('wElementResizeEmitOutsideZone') emitResizeOutsideZone = false;
  @Input('wElementResizeDisabled') disabled = false;

  @Output('wElementResize') onChange = new EventEmitter<ElementDimension>();

  private disabled$ = new BehaviorSubject<boolean>(false);
  private subs = subscriptions();

  constructor(
    private elementRef: ElementRef,
    private ngZone: NgZone,
  ) {}

  ngOnChanges({disabled}: WSimpleChanges<ElementResizeDirective>) {
    if (disabled) {
      this.disabled$.next(disabled.currentValue);
    }
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.subs.add(
        this.disabled$
          .pipe(
            switchMap(disabled =>
              disabled ? EMPTY : this.fromResizeObserver(this.elementRef.nativeElement as HTMLElement),
            ),
            // This avoids "executing a cancelled action" error, which is randomly thrown in tests by asyncScheduler
            TEST ? tap() : throttleTime(this.throttleTime, undefined, {leading: true, trailing: true}),
            distinctUntilChanged(isEqual),
            this.skipFirstEvent ? skip(1) : tap(),
          )
          .subscribe(this.notifyChange),
      );
    });
  }

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  private fromResizeObserver(elem: HTMLElement): Observable<ElementDimension> {
    return new Observable<ElementDimension>(observer => {
      const resizeObserver = new ResizeObserver(entries => {
        // This avoids "ResizeObserver loop limit exceeded" error
        requestAnimationFrame(() => {
          const entry = entries[0];

          if (
            entry.target === elem &&
            /*
             * Resize observer also informs about size changes when element becomes hidden.
             * Element's size is `width: 0; height: 0` in this case, so this notification is useless and we need to
             * filter it out.
             */
            isElementVisible(elem)
          ) {
            // Starting from Chrome 84 borderBoxSize is an array
            const borderBoxSize = Array.isArray(entry.borderBoxSize) ? entry.borderBoxSize[0] : entry.borderBoxSize;

            if (borderBoxSize) {
              observer.next({
                width: borderBoxSize.inlineSize,
                height: borderBoxSize.blockSize,
              });
            } else {
              observer.next(this.getElemDimensions(entry.target));
            }
          }
        });
      });

      resizeObserver.observe(elem);

      return () => {
        resizeObserver.unobserve(elem);
      };
    });
  }

  private getElemDimensions(elem: Element): ElementDimension {
    return pick(elem.getBoundingClientRect(), 'width', 'height');
  }

  private notifyChange = (dimensions: ElementDimension) => {
    if (this.emitResizeOutsideZone) {
      this.onChange.emit(dimensions);
    } else {
      this.ngZone.run(() => {
        this.onChange.emit(dimensions);
      });
    }
  };
}
