import light1Image from '@shared-images/page-patterns/light_1.png';
import light2Image from '@shared-images/page-patterns/light_2.png';
import light2Image2x from '@shared-images/page-patterns/light_2@2x.png';
import light3Image from '@shared-images/page-patterns/light_3.png';
import light3Image2x from '@shared-images/page-patterns/light_3@2x.png';
import light4Image from '@shared-images/page-patterns/light_4.png';
import light5Image from '@shared-images/page-patterns/light_5.png';
import light6Image from '@shared-images/page-patterns/light_6.png';
import light6Image2x from '@shared-images/page-patterns/light_6@2x.png';
import light7Image from '@shared-images/page-patterns/light_7.png';
import light7Image2x from '@shared-images/page-patterns/light_7@2x.png';
import dark1Image from '@shared-images/page-patterns/dark_1.png';
import dark1Image2x from '@shared-images/page-patterns/dark_1@2x.png';
import dark2Image from '@shared-images/page-patterns/dark_2.png';
import dark2Image2x from '@shared-images/page-patterns/dark_2@2x.png';
import dark3Image from '@shared-images/page-patterns/dark_3.png';
import dark4Image from '@shared-images/page-patterns/dark_4.png';
import dark4Image2x from '@shared-images/page-patterns/dark_4@2x.png';
import dark5Image from '@shared-images/page-patterns/dark_5.png';
import dark5Image2x from '@shared-images/page-patterns/dark_5@2x.png';
import dark6Image from '@shared-images/page-patterns/dark_6.png';
import dark6Image2x from '@shared-images/page-patterns/dark_6@2x.png';
import light1ThumbImage from '@shared-images/page-patterns/light_1_thumbnail.svg';
import light2ThumbImage from '@shared-images/page-patterns/light_2_thumbnail.svg';
import light3ThumbImage from '@shared-images/page-patterns/light_3_thumbnail.svg';
import light4ThumbImage from '@shared-images/page-patterns/light_4_thumbnail.svg';
import light5ThumbImage from '@shared-images/page-patterns/light_5_thumbnail.svg';
import light6ThumbImage from '@shared-images/page-patterns/light_6_thumbnail.svg';
import light7ThumbImage from '@shared-images/page-patterns/light_7_thumbnail.svg';
import dark1ThumbImage from '@shared-images/page-patterns/dark_1_thumbnail.svg';
import dark2ThumbImage from '@shared-images/page-patterns/dark_2_thumbnail.svg';
import dark3ThumbImage from '@shared-images/page-patterns/dark_3_thumbnail.svg';
import dark4ThumbImage from '@shared-images/page-patterns/dark_4_thumbnail.svg';
import dark5ThumbImage from '@shared-images/page-patterns/dark_5_thumbnail.svg';
import dark6ThumbImage from '@shared-images/page-patterns/dark_6_thumbnail.svg';

import {Size, Url} from '../../types';
import {HexColor} from '../../types/lib';

import {LcapPageBackgroundPatternName, LcapPageBackgroundPatternUrls, LcapPageColorBackground} from './lcap-page.types';

export const LCAP_PAGE_DEFAULT_BACKGROUND_COLOR: HexColor = '#fafbfc';

export const LCAP_DEFAULT_PAGE_BACKGROUND: LcapPageColorBackground = {
  style: 'color',
  color: LCAP_PAGE_DEFAULT_BACKGROUND_COLOR,
};

export const LCAP_PAGE_BACKGROUND_PATTERN_SIZE: Size = {width: 1920, height: 1920};

export const LCAP_PAGE_BACKGROUND_PATTERNS = [
  'light-1',
  'light-2',
  'light-3',
  'light-4',
  'light-5',
  'light-6',
  'light-7',
  'dark-1',
  'dark-2',
  'dark-3',
  'dark-4',
  'dark-5',
  'dark-6',
] as const;

export const PATTERN_NAME_TO_URL_MAP = new Map<LcapPageBackgroundPatternName, LcapPageBackgroundPatternUrls>([
  ['light-1', [light1Image]],
  ['light-2', [light2Image, light2Image2x]],
  ['light-3', [light3Image, light3Image2x]],
  ['light-4', [light4Image]],
  ['light-5', [light5Image]],
  ['light-6', [light6Image, light6Image2x]],
  ['light-7', [light7Image, light7Image2x]],
  ['dark-1', [dark1Image, dark1Image2x]],
  ['dark-2', [dark2Image, dark2Image2x]],
  ['dark-3', [dark3Image]],
  ['dark-4', [dark4Image, dark4Image2x]],
  ['dark-5', [dark5Image, dark5Image2x]],
  ['dark-6', [dark6Image, dark6Image2x]],
]);

export const PATTERN_NAME_TO_THUMBNAIL_URL_MAP = new Map<LcapPageBackgroundPatternName, Url>([
  ['light-1', light1ThumbImage],
  ['light-2', light2ThumbImage],
  ['light-3', light3ThumbImage],
  ['light-4', light4ThumbImage],
  ['light-5', light5ThumbImage],
  ['light-6', light6ThumbImage],
  ['light-7', light7ThumbImage],
  ['dark-1', dark1ThumbImage],
  ['dark-2', dark2ThumbImage],
  ['dark-3', dark3ThumbImage],
  ['dark-4', dark4ThumbImage],
  ['dark-5', dark5ThumbImage],
  ['dark-6', dark6ThumbImage],
]);
