import {inject} from '@angular/core';
import {has, isPlainObject, isString} from 'lodash';
import {gzipSync, strToU8} from 'fflate';
import {Observable, PartialObserver} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

import {UrlParams, UrlPattern} from '../utils/url-pattern';
import {getHttpStatusText} from '../utils/http-status-codes';
import {convertUrlQueryToHttpParams} from '../utils/convert-url-query-to-http-params';
import {GlobalConfig, Hash} from '../types';
import {UrlQuery} from '../types/angular';
import {GLOBAL_CONFIG_TOKEN} from '../tokens/global-config.token';

export interface ResourceConfig {
  url: string;
}

interface RequestOptions {
  query?: UrlQuery;
  headers?: Hash<string>;
}

interface RequestDataOptions {
  compress?: boolean;
}

interface PreprocessRequestResult {
  payload: RequestData | ArrayBuffer | null;
  headers?: HttpHeaders;
}

type HttpResourceResponse<TResult> = HttpResourceSuccessfulResponse<TResult> | HttpResourceErrorResponse;

interface HttpResourceSuccessfulResponse<TResult> {
  result: TResult;
}

interface HttpResourceErrorResponse {
  error: string | HTTPResourceErrorObject;
}

interface HTTPResourceErrorObject {
  message?: string;
  details?: any;
}

type RequestData = Hash<any> | FormData;

export interface Request<TResult> extends Promise<TResult> {
  abort(): void;
  isAborted?: boolean;
}

export interface BaseRequestError extends Error {
  isUnauthorized: boolean;
  isServerError: boolean;
  isNotFound: boolean;
  isServiceUnavailable: boolean;
}

export class RequestError<TDetails = any> extends Error implements BaseRequestError {
  override name = 'RequestError';

  constructor(
    public override message: string,
    /*
     * `true` if:
     *   - there is no network connection
     *   - http code is 500
     *   - we received malformed response (should be JSON with the shape of `HttpResourceResponse`)
     */
    public system: boolean,
    public details?: TDetails,
    // HTTP status code
    public status?: number,
  ) {
    super(message);
  }

  get isNotFound(): boolean {
    return this.status === 404;
  }

  get isUnauthorized(): boolean {
    return this.status === 401;
  }

  get isServerError(): boolean {
    return this.status === 500;
  }

  get isServiceUnavailable(): boolean {
    return this.status === 503;
  }
}

export class RequestAbortError extends Error {
  override name = 'RequestAbortError';

  constructor() {
    super('Request aborted');
  }
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export class HttpResource {
  // Workaround for https://github.com/Microsoft/TypeScript/issues/3841
  ['constructor']: typeof HttpResource;

  private urlPattern: UrlPattern;
  private globalConfig: GlobalConfig | null;

  constructor(
    private http: HttpClient,
    config: ResourceConfig,
  ) {
    this.urlPattern = new UrlPattern(config.url);
    this.globalConfig = inject(GLOBAL_CONFIG_TOKEN, {optional: true});
  }

  url(params: UrlParams | null = null): string {
    return this.urlPattern.url(params);
  }

  get<TResult = any>(params: UrlParams | null = null, opts: RequestOptions = {}): Request<TResult> {
    const {headers} = this.preprocessRequest(null, opts);

    return this.convertResponse<TResult>(
      this.http.get<HttpResourceResponse<TResult>>(this.url(params), {
        params: convertUrlQueryToHttpParams(opts.query),
        headers,
      }),
    );
  }

  getAll<TResult = any>(opts?: RequestOptions): Request<TResult> {
    return this.get(null, opts);
  }

  post<TResult = any>(
    params: UrlParams | null = null,
    data: RequestData | null = null,
    opts: RequestOptions & RequestDataOptions = {},
  ): Request<TResult> {
    const {payload, headers} = this.preprocessRequest(data, opts);

    return this.convertResponse<TResult>(
      this.http.post<HttpResourceResponse<TResult>>(this.url(params), payload, {
        params: convertUrlQueryToHttpParams(opts.query),
        headers,
      }),
    );
  }

  create<TResult = any>(data: RequestData | null = null, opts?: RequestOptions & RequestDataOptions): Request<TResult> {
    return this.post(null, data, opts);
  }

  put<TResult = any>(
    params: UrlParams | null = null,
    data: RequestData | null = null,
    opts: RequestOptions & RequestDataOptions = {},
  ): Request<TResult> {
    const {payload, headers} = this.preprocessRequest(data, opts);

    return this.convertResponse<TResult>(
      this.http.put<HttpResourceResponse<TResult>>(this.url(params), payload, {
        params: convertUrlQueryToHttpParams(opts.query),
        headers,
      }),
    );
  }

  patch<TResult = any>(
    params: UrlParams | null = null,
    data: RequestData | null = null,
    opts: RequestOptions & RequestDataOptions = {},
  ): Request<TResult> {
    const {payload, headers} = this.preprocessRequest(data, opts);

    return this.convertResponse<TResult>(
      this.http.patch<HttpResourceResponse<TResult>>(this.url(params), payload, {
        params: convertUrlQueryToHttpParams(opts.query),
        headers,
      }),
    );
  }

  delete<TResult = any>(params: UrlParams | null = null, opts: RequestOptions = {}): Request<TResult> {
    const {headers} = this.preprocessRequest(null, opts);

    return this.convertResponse<TResult>(
      this.http.delete<HttpResourceResponse<TResult>>(this.url(params), {
        params: convertUrlQueryToHttpParams(opts.query),
        headers,
      }),
    );
  }

  private convertResponse<TResult>(responseObservable: Observable<any>): Request<TResult> {
    let aborted = false;
    let abort = () => {
      aborted = true;
    };

    const promise = new Promise((resolve, reject) => {
      if (aborted) {
        return reject(new RequestAbortError());
      }

      const subscription = responseObservable.subscribe(
        response => {
          if (this.isValidErrorResponse(response)) {
            const {message, details} = this.normalizeResponseError(response.error);

            return reject(new RequestError(message || '', false, details, 200));
          }

          if (this.isValidSuccessfulResponse(response)) {
            resolve(response.result);
          } else {
            resolve(response);
          }
        },
        errorResponse => {
          let message: string;
          let status: number | undefined;
          let system = true;
          let details: any = errorResponse;

          if (errorResponse instanceof HttpErrorResponse) {
            status = errorResponse.status;

            if (status !== 0 && status !== 500 && this.isValidErrorResponse(errorResponse.error)) {
              system = false;

              const error = this.normalizeResponseError(errorResponse.error.error);

              message = error.message || '';
              details = error.details;
            } else {
              message = getHttpErrorMessage(errorResponse);
            }
          } else {
            message = 'Internal error';
          }

          reject(new RequestError(message, system, details, status));
        },
      );

      abort = () => {
        subscription.unsubscribe();
        reject(new RequestAbortError());
      };
    });

    const request = promise as Request<TResult>;

    request.abort = () => {
      request.isAborted = true;
      abort();
    };

    return request;
  }

  private isValidSuccessfulResponse<TResult>(response: any): response is HttpResourceSuccessfulResponse<TResult> {
    return isPlainObject(response) && has(response, 'result');
  }

  private isValidErrorResponse(response: any): response is HttpResourceErrorResponse {
    return (
      isPlainObject(response) &&
      (isString(response.error) ||
        (isPlainObject(response.error) && (isString(response.error.message) || has(response.error, 'details'))))
    );
  }

  private normalizeResponseError(error: HttpResourceErrorResponse['error']): HTTPResourceErrorObject {
    return isString(error) ? {message: error} : error;
  }

  private preprocessRequest(
    data: RequestData | null,
    opts: RequestOptions & RequestDataOptions,
  ): PreprocessRequestResult {
    // window.(Workato/Lcap).disablePayloadCompression can be set in browser console to enable debugging payload content
    if (opts.compress && this.globalConfig && !this.globalConfig.disablePayloadCompression) {
      try {
        return {
          payload: gzipSync(strToU8(JSON.stringify(data))).buffer,
          headers: new HttpHeaders({
            ...opts.headers,
            'Content-Type': 'application/json; charset=utf-8',
            'Content-Encoding': 'gzip',
          }),
        };
        // Returning uncompressed data if there were some error during compression
        // eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
      } catch {}
    }

    return {
      payload: data,
      headers: opts.headers ? new HttpHeaders(opts.headers) : undefined,
    };
  }
}

// Adding `update` alias for readability reasons
HttpResource.prototype.update = HttpResource.prototype.put;

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export interface HttpResource {
  update<TResult = any>(params?: UrlParams | null, data?: RequestData | null, opts?: RequestOptions): Request<TResult>;
}

export function getHttpErrorMessage(errorResponse: HttpErrorResponse): string {
  const {status} = errorResponse;
  let message: string;

  if (status === 0) {
    message = 'No internet connection';
  } else if (status === 500) {
    message = 'Internal server error';

    const requestId = errorResponse.headers.get('X-Request-ID');

    if (requestId) {
      message += ` (Request ID: ${requestId})`;
    }
  } else {
    message = errorResponse.message || errorResponse.statusText;

    /*
     * HTTP status text (reason phrase) is optional and defaults to "OK", so it this is the case
     * we get it from our own list.
     */
    if (message === 'OK') {
      message = getHttpStatusText(status, 'Unknown server error');
    }
  }

  return message;
}

/**
 * Use this if you want to use HttpResource with RxJS in constructions like switchMap or when you need to unsubscribe manually
 */
export function getRequestAsObservable<T>(request: Request<T>): Observable<T> {
  return new Observable((observer: PartialObserver<T>) => {
    request.then(
      result => {
        observer.next?.(result);
        observer.complete?.();
      },
      error => {
        if (error instanceof RequestAbortError) {
          observer.complete?.();
        } else {
          observer.error?.(error);
        }
      },
    );

    return () => request.abort();
  });
}

export function getEmptyRequest<T>(data: T): Request<T> {
  const emptyPromise = Promise.resolve(data) as Request<T>;

  emptyPromise.abort = () => ({});

  return emptyPromise;
}
