import {Subject} from 'rxjs';
import {EnvironmentInjector, EventEmitter, Injector, TemplateRef, ViewContainerRef} from '@angular/core';

import {ComponentClass} from '../../types/angular';
import {Point} from '../../types';
import {ThemingContext} from '../../directives/theming-context.directive';
import {DialogContentComponent} from '../dialog/dialog.types';

import {OverlayOrigin} from './overlay-origin';
import {OverlayComponent} from './overlay/overlay.component';

export type HorizontalPlacement = 'left' | 'right' | 'center';
export type VerticalPlacement = 'top' | 'bottom' | 'center';
export type OverlayAction = 'close' | 'position';

export enum OverlayEvent {
  ESCAPE_PRESS,
  WINDOW_RESIZE,
  CLICK_OUTSIDE,
  CUSTOM_ACTION,
}

export interface ClickOutsideAction {
  action: Exclude<OverlayAction, 'position'>;
  // This is `click` by default
  triggerEvent?: 'click' | 'mousedown';
  // This is `false` by default
  skipOtherHandlers?: boolean;
  elements?: () => Array<HTMLElement | null | undefined>;
}

export interface EscapePressAction {
  action: Exclude<OverlayAction, 'position'>;
  // This is `true` by default
  skipOtherHandlers?: boolean;
}

export interface OverlayPlacement {
  originX: HorizontalPlacement;
  originY: VerticalPlacement;
  overlayX: HorizontalPlacement;
  overlayY: VerticalPlacement;
  offsetX?: number;
  offsetY?: number;
}

export interface OverlayClosedEventPayload<TResult = any> {
  result?: TResult;
  reason: OverlayEvent;
}

export interface OverlayInstance<TContentProps extends object = any, TResult = any> {
  type?: 'context-menu' | 'dropdown' | 'tooltip';
  opened: boolean;
  hide: (result?: TResult, reason?: OverlayEvent) => void;
  destroyed: boolean;
  mobileView: boolean;
  closed: Subject<OverlayClosedEventPayload<TResult>>;
  result: Promise<TResult | undefined>;
  position: (placement?: string | OverlayPlacement) => void;
  updateOrigin: (origin: HTMLElement) => void;
  element: HTMLElement;
  component: OverlayComponent<TContentProps>;
  originElement: HTMLElement | null;
  updateContentProps: (props: Partial<TContentProps>) => void;
  updatePointerOrigin: (point: Point) => void;
  contentComponent?: DialogContentComponent;
}

export type OverlayContent<TContentProps = any, TContext = any> =
  | ComponentClass<TContentProps>
  | OverlayContentComponent<TContentProps>
  | OverlayContentTemplate<TContext>;

export interface OverlayShowOptions<TContentProps extends object = any> {
  type?: OverlayInstance['type'];
  props?: Partial<TContentProps>;
  placement?: string | OverlayPlacement;
  attachTo?: HTMLElement | 'parent-overlay' | 'parent-overlay-or-body';
  zIndex?: number;
  class?: string;
  origin?: HTMLElement | OverlayOrigin | null;
  beforeShow?: EventEmitter<OverlayInstance<TContentProps>>;
  // displays with an opaque overlay in mobile, changes the positioning of overlay
  supportMobileView?: boolean;
  // displays an overlay regardless of screen size
  withBackground?: boolean;
  // Default is 'shift', extra-space only works through overlay-directive
  fitStrategy?: 'shift' | 'resize' | 'extra-space';
  /*
   * If `true`, initial overlay placement (it's first position just after overlay creation) will be preferred over
   * default `placement`.
   * It means that if there was no space to show overlay in default `placement` and it was flipped, it will remain
   * flipped even if more space will appear to change placement to default.
   * Introduced to address https://github.com/workato/issues/issues/12180
   */
  preferInitialPlacement?: boolean;
  /*
   * If `true`, browser window viewport will be used as overlay boundaries instead of the document body.
   * Because of this user won't need to scroll the page in order to see the whole overlay, but it's position may not
   * be as accurate as it will be shifted to fit browser window.
   */
  preferVisibility?: boolean;
  injector?: Injector;
  onEscape?: EscapePressAction | EscapePressAction['action'];
  onWindowResize?: OverlayAction;
  onClickOutside?: ClickOutsideAction | ClickOutsideAction['action'];
  themingContext?: ThemingContext;
}

export interface OverlayOptions<TContentProps extends object = any> extends OverlayShowOptions<TContentProps> {
  origin: OverlayOrigin | null;
}

export interface OverlayContentTemplate<TContext = any> {
  templateRef: TemplateRef<TContext>;
  viewContainerRef: ViewContainerRef;
}

export interface OverlayContentComponent<TContentProps = any> {
  component: ComponentClass<TContentProps>;
  viewContainerRef: ViewContainerRef;
  envInjector?: EnvironmentInjector;
}
